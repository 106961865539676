// extracted by mini-css-extract-plugin
export var ArtistDescription = "Bando-module--ArtistDescription--ApA70";
export var ArtistInfos = "Bando-module--ArtistInfos--pwrub";
export var ButtonWrapper = "Bando-module--ButtonWrapper --4Dx3L";
export var CardWrapper = "Bando-module--CardWrapper--JPJ8Z";
export var CarrouselWrapper2 = "Bando-module--CarrouselWrapper2--rB4E1";
export var Citations = "Bando-module--Citations--xyY4f";
export var DescriptionWrapper = "Bando-module--DescriptionWrapper--nX4on";
export var ImageWrapper = "Bando-module--ImageWrapper--E4EPl";
export var LinkWrapper = "Bando-module--LinkWrapper--syVSU";
export var MobileProtrait = "Bando-module--MobileProtrait--ySlxv";
export var More = "Bando-module--More--kdEPE";
export var MoreButton = "Bando-module--MoreButton--SmZSF";
export var NameWrapper = "Bando-module--NameWrapper--uKJ0i";
export var PdpWrapper = "Bando-module--PdpWrapper--xZMJo";
export var PhotosWrapper = "Bando-module--PhotosWrapper--Nj1F2";
export var ProfilWrapper = "Bando-module--ProfilWrapper--A6Wag";
export var TitleWrapper = "Bando-module--TitleWrapper--u2uLT";
export var Wrapper = "Bando-module--Wrapper--FXKoy";