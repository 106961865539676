import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Bando.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';

import { ListWrapper } from "../../Expositions/Expositions.module.css";
import EventCard from "../../../../components/EventCard";
import BandoPhoto1 from "../../../../res/bando/portrait.jpg"
import BandoPhoto3 from "../../../../res/Photos site/Bando/Bando-Tag-03.jpg"
import BandoPhoto4 from "../../../../res/Photos site/Bando/Bando-Tag-06.jpg"
import Button from "./../../../../components/Button";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "Bando",
  name: "Bando",
  description: "Philippe Lehman, known as Bando, is a Parisian graffiti artist from the 1980s and 1990s and a record producer. Born into a wealthy family, he grew up in Paris. He discovered graffiti in New York thanks to Soy TWA and was influenced by graffiti artists like Seen and Dondi White. All of Europe was passing through Paris, from 1985, and Bando's style, further nourished by his encounters with Mode2 and the TCA, coming from London, then with Shoe and the USA coming from Amsterdam, was sharpening to establish his reputation as the \"Godfather of European graffiti\". He founded the Bomb Squad 2 crew which was composed at its peak of Boxer, Colt, Delta, Mode 2, Shoe. Bando encouraged some New York artists to come to Paris such as JonOne, who had previously met the Parisian stylist Gigi in New York. Bando was among the artists represented in the Paris Graffiti Museum, L'Aérosol - Maquis-art Hall of Fame organized by maquis-art. One of his most important exhibitions was at the Pinacothèque de Paris in 2015, Les chefs-d'oeuvres du graffiti sur toile de Basquiat à Bando en 2015 by Alain-Dominique Galizzia.",

  pdp: BandoPhoto1,
  alt_pdp: "Photo de profil de Bando.",
  photos: [
    { src: BandoPhoto1, name: "Bando" },
    { src: BandoPhoto3, name: "Bando" },
    { src: BandoPhoto4, name: "Bando" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: []
};

const Bando = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>BANDO <h2 style={{paddingLeft: "16px"}}>(Philippe Lehman - Franco-American)</h2></h1>
          <h3 className={NameWrapper} >Born in 1965</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        </div>
      </div>
      <br />

      <div className={PhotosWrapper}>
        <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
        <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }
            }
            >
              Contact-us
            </Button>
        </div>
    </div>
  );
};

export default Bando;